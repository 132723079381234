import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  connect() {
    this.initialize_zoomify();
  }

  initialize_zoomify() {
    const id = this.container_id;
    const path = this.zoomify_path;
    const opt = this.zoomify_options_string;
    this.set_zoomify_height();
    Z.showImage(id, path, opt);
  }

  set_zoomify_height() {
    const height = window.innerHeight*0.65
    this.containerTarget.style.height = `${height}px`
  }

  get container_id() {
    return this.containerTarget.id;
  }

  get zoomify_path() {
    return this.data.get("path")
  }

  get zoomify_options_string() {
    const opts = this.zoomify_options
    return Object.keys(opts).map(function(k) { return `${k}=${opts[k]}` }).join('&')
  }

  get zoomify_options() {
    return {
      "zSkinPath": "/skins/Default",
      "zToolbarVisible": 1,
      "zLogoVisible": 0,
      "zMinimizeVisible": 0,
      "zHelpVisible": 0,
      "zNavigatorVisible": 0,
    }
  }
}
