import { Controller } from 'stimulus';

export default class extends Controller {
  click() {
    window.location = this.link;
  }

  get link() {
    return this.data.get('link');
  }
}
