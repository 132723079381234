import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'materialize-css/dist/js/materialize';
import Rails from '@rails/ujs';
import 'ZoomifyImageViewerExpress-src';
import 'jquery-rotate';
import 'controllers';
import 'javascripts';

Rails.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true);
// const imagePath = (name) => images(name, true);

import '../stylesheets/application';
