$(function() {
  var new_additional_files = [];

  $('#new-additional-files-form').on('change', function(data){
    $('.new_additional_files').remove();
    $.each(data.target.files, function(index, f){
      if(!f.type.match(/text|pdf|mp3|wav|mp4/)) {
        alert('\
        対応した拡張子のファイルを選択してください\n\
        テキストファイル: .txt .pdf\n\
        音声ファイル: .mp3 .wav\n\
        動画ファイル: .mp4\n\
        ');
        $('#new-additional-files-form').val('');
        return;
      }
      $('.additional-files-add-btn').click();
      var last_new_additional_file = $('#additional-files-field .nested-fields').last();
      new_additional_files.push(last_new_additional_file);
      last_new_additional_file.find('.additional-file-badge').append(
        $('<span></span>', { 'class': 'new badge' })
      );
      last_new_additional_file.find('.additional-file-file-name').val(f.name);
      last_new_additional_file.find('.additional-file-display-name').val(f.name);
      last_new_additional_file.addClass("new_additional_files");
    });
  });
})
