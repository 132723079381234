import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "ul" ]

  connect() {
    this.right_nav();
  }

  right_nav() {
    const opts = { edge: 'right' };
    M.Sidenav.init(this.ulTarget, opts);
  }
}
