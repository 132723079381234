function syncFixheadersWidth() {
  $('.admin-table-fixheader').width($('.admin-table-preview').width());
  $('#fixheader-number').width($('#preview-number').width());
  $('#fixheader-branch').width($('#preview-branch').width());
  $('#fixheader-name').width($('#preview-name').width());
  $('#fixheader-scientific-name').width($('#preview-scientific-name').width());
  $('#fixheader-summary').width($('#preview-summary').width());
  $('#fixheader-era').width($('#preview-era').width());
  $('#fixheader-measured-values').width($('#preview-measured-values').width());
  $('#fixheader-number-of-members').width($('#preview-number-of-members').width());
  $('#fixheader-note').width($('#preview-note').width());
  $('#fixheader-classificatoin').width($('#preview-classification').width());
  $('#fixheader-collection').width($('#preview-collection').width());
  $('#fixheader-site').width($('#preview-site').width());
  $('#fixheader-theme').width($('#preview-theme').width());
  $('#fixheader-openlevel').width($('#preview-openlevel').width());

  $('#fixheader-import-button').width($('.admin-table-preview').width());
}

function syncFixHeadersOffsetLeft() {
  $('#fixheader-import-button').offset({ left: $('#import-button').offset().left - parseInt($('#fixheader-import-button').css('paddingLeft'))});
  $('#fixheader-import-notice').offset({ left: $('#import-button').offset().left - parseInt($('#fixheader-import-button').css('paddingLeft')) + 140});
  $('.admin-table-fixheader').offset({ left: $('.admin-table-preview').offset().left});
}


$(document).ready(function(){
  if(location.pathname != '/admin/catalogs/preview') {
    return;
  }

  syncFixheadersWidth();

  const admin_table_preview = $('.admin-table-preview');
  $(window).scroll(function() {
    if($(window).scrollTop() > admin_table_preview.offset().top) {
      $('#fixheader-import-button').show();
      $('#fixheader-import-notice').show();
      $('.admin-table-fixheader').show();
      $('.admin-table-fixheader').css("left", -$(window).scrollLeft());
      syncFixHeadersOffsetLeft();
    } else {
      $('#fixheader-import-button').hide();
      $('#fixheader-import-notice').hide();
      $('.admin-table-fixheader').hide();
    }
  });

  $(window).resize(function() {
    syncFixheadersWidth();

    // スマホレイアウトの場合サイドナビゲーションがハンバーガーメニューになり、レイアウトが変わることに対応
    syncFixHeadersOffsetLeft();
  });
});
