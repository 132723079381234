$(document).ready(function(){
  // /admin/records/:id/edit, /admin/records/new でバリデーションエラーが発生した場合は/admin/recordsにURLが変わるため、
  // maskを見て資料編集画面、資料追加画面か資料一覧画面かを判別
  if(!location.pathname.match(/\/admin\/records\/\d+\/edit|\/admin\/records\/new|\/admin\/records/) || !$('div').hasClass('mask')) {
    return;
  }

  $("#raw-zoomify-image-form").change(function(){
    var files = event.target.files;
    if(files.length == 0) return;
    var file = files[0];
    if(!file.type.match(/image/)) {
      alert("画像ファイルを選択してください");
      return;
    }

    var reader = new FileReader();
    reader.onload = function(event) {
      $(".after-zoomify-image")[0].src = reader.result;
      var exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(this.result));
      rotateZoomifyImage(exif.Orientation);
    }
    reader.readAsDataURL(file);
  });
});

function base64ToArrayBuffer (base64) {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

// プレビュー等で画像を回転させた場合、画像自体は回転されずEXIF情報に回転情報が保存される。そのためEXIF情報をもとに画像を回転
function rotateZoomifyImage(orientation) {
  switch (orientation) {
    case 3: // 180度回転
      $(".after-zoomify-image").rotate(180);
      break;
    case 6: // 時計回りに90度回転
      $(".after-zoomify-image").rotate(90);
      $(".after-zoomify-image").css('margin-top', '50px');
      $(".after-zoomify-image").css('margin-bottom', '50px');
      break;
    case 8: // 時計回りに270度回転
      $(".after-zoomify-image").rotate(270);
      $(".after-zoomify-image").css('margin-top', '50px');
      $(".after-zoomify-image").css('margin-bottom', '50px');
      break;
    default:
      break;
  }
}
