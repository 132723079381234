$(document).ready(function(){
  // /admin/records/:id/edit, /admin/records/new でバリデーションエラーが発生した場合は/admin/recordsにURLが変わるため、
  // maskを見て資料編集画面、資料追加画面か資料一覧画面かを判別
  if(!$('div').hasClass('mask')) {
    return;
  }

  $(".mask").click(function() {
    $("input[id='thumbnail-form']").click();
  });

  $("#thumbnail-form").change(function(){
    var files = event.target.files;
    if(files.length == 0) return;
    var file = files[0];
    if(!file.type.match(/image/)) {
      alert("画像ファイルを選択してください");
      return;
    }

    var reader = new FileReader();

    reader.onload = function(event) {
      $("#thumbnail-preview")[0].src = reader.result;
      var exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(this.result));
      rotateThumbnailImage(exif.Orientation);
    }

    reader.readAsDataURL(file);
  });

  $('.mask').offset({ top: $('#thumbnail-preview').offset().top })
  $('.mask').width($('#thumbnail-preview').width());
  $('.mask').height($('#thumbnail-preview').height());
  $('.mask').css({ 'margin-bottom': -$('#thumbnail-preview').height() });
  $('.caption').css({ 'padding-top': $('.mask').height() / 2 - 10 })
});

function base64ToArrayBuffer (base64) {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

// プレビュー等で画像を回転させた場合、画像自体は回転されずEXIF情報に回転情報が保存される。そのためEXIF情報をもとに画像を回転
function rotateThumbnailImage(orientation) {
  switch (orientation) {
    case 3: // 180度回転
      $("#thumbnail-preview").rotate(180);
      break;
    case 6: // 時計回りに90度回転
      $("#thumbnail-preview").rotate(90);
      $('#thumbnail-preview').css({ 'margin-top': 30 });
      $('.mask').offset({ top: $('#thumbnail-preview').offset().top });
      $('.mask').height($('#thumbnail-preview').width());
      $('.mask').css({ 'margin-bottom': -($('#thumbnail-preview').height() + 50) });
      $('.caption').css({ 'padding-top': $('#thumbnail-preview').width() / 2 - 10 });
      break;
    case 8: // 時計回りに270度回転
      $("#thumbnail-preview").rotate(270);
      $('#thumbnail-preview').css({ 'margin-top': 30 });
      $('.mask').offset({ top: $('#thumbnail-preview').offset().top });
      $('.mask').height($('#thumbnail-preview').width());
      $('.mask').css({ 'margin-bottom': -($('#thumbnail-preview').height() + 50) });
      $('.caption').css({ 'padding-top': $('#thumbnail-preview').width() / 2 - 10 });
      break;
    default:
      break;
  }
}
