import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "ul" ]

  connect() {
    this.left_nav();
  }

  left_nav() {
    const opts = { edge: 'left' };
    M.Sidenav.init(this.ulTarget, opts);
  }
}
