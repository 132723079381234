$(document).ready(function(){
  $('.candidate-additional-image').on('click', function(e){
    $.each($('.candidate-additional-image img'), function(index, additionalImages){
      $(additionalImages).removeClass('selected');
    });
    $(e.target).closest('.candidate-additional-image').children('img').addClass('selected');
    $('.representative-additional-image img').attr(
      "src", $(e.target).closest('.candidate-additional-image').children('img').attr('src')
    );
    $('.representative-additional-image-name').text(
      $(e.target).closest('.candidate-additional-image').find('.additional-image-name span').text()
    );
  });
});
