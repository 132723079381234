$(function() {
  if(sessionStorage.getItem('index')) {
    var index = sessionStorage.getItem('index');
    switchDisplayStyle(index)
  }

  $('.display-style-tab li.display-style-tab-element').click(function() {
    var index = $('.display-style-tab li').index(this);
    switchDisplayStyle(index)

    sessionStorage.setItem('index', index);
  });
});

function switchDisplayStyle(index) {
  $('.content li').removeClass('hide');
  $('.content li').css('display','none');
  $('.content li').eq(index).css('display','block');

  $('.display-style-tab li.display-style-tab-element a').removeClass('disabled');
  $('.display-style-tab li.display-style-tab-element a').eq(index).addClass('disabled')
};
